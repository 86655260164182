<template>
  <div class="save-form-page">
    <h6 class="ym-title">
      <el-link class="ym-title-link" href="#/listQuarantines">检疫信息 <i class="el-icon-arrow-right"></i> </el-link>
      <span>{{ title }}</span>
    </h6>
    <div class="ym-panel-general">
      <el-form :model="form" :rules="rules" ref="form" label-width="282px">
        <el-form-item prop="productId" label="产品名称：">
          <select-page
            label="productName"
            value="productId"
            apiFile="currency"
            apiName="listProducts"
            placeholder="请选择产品名称"
            filter="productName"
            :selected.sync="productId"
            :params="productParams"
            @change="onProductChange"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item prop="afcBatchId" label="产品批次编号：">
          <el-input
            v-if="id"
            v-model="form.batchNum"
            maxlength="50"
            disabled
            size="medium"
          ></el-input>
          <select-page
            v-else
            label="batchNum"
            value="afcBatchId"
            apiFile="currency"
            apiName="listAfcBatchs"
            placeholder="请选择产品批次编号"
            filter="batchNum"
            :selected.sync="afcBatchId"
            :params="batchParams"
            @change="onBatchChange"
            :disabled="isOpen"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item prop="aqCompanyId" label="检测机构：">
          <select-page
            label="companyName"
            value="companyId"
            apiFile="currency"
            apiName="listCompanys"
            placeholder="请选择检测机构"
            filter="companyName"
            :selected.sync="companyId"
            :params="companyParams"
            @change="onCompanyChange"
            clearable
          ></select-page>
        </el-form-item>

        <el-form-item prop="aqDate" label="检疫日期：">
          <el-date-picker
            v-model="form.aqDate"
            type="date"
            placeholder="请选择检疫日期"
            size="medium"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="aqFlag" label="是否合格：">
          <el-select
            v-model="form.aqFlag"
            placeholder="请选择是否合格"
            clearable
            size="medium"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="aqRemark" label="备注：">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="form.aqRemark"
            maxlength="50"
            show-word-limit
            size="medium"
            rows="3"
          >
          </el-input>
        </el-form-item>

        <el-form-item prop="files" label="检疫报告：">
          <uploadFile
            :limit="5"
            :sizeLimit="10240"
            :businessType="businessType"
            :file-list="fileList"
            @onSuccess="handleAvatarSuccess"
            typeErrorMsg="请上传jpg、jpeg、png、bmp图片或pdf类型的文件"
            :acceptTypes='/.pdf$|.PDF$|.jpg$|.jpeg$|.png$|.bmp$|.JPG$|.JPEG$|.PNG$|.BMP$/'
            @onRemove="onRemove"
            :file-list-type="listType"
          >
            <el-button size="small" plain>
              <i class="iconfont iconshangchuan"></i>
              上传检疫报告
            </el-button>
            <div slot="tip" class="el-upload__tip">
              *支持最多上传5个附件，单个附件最大10MB，格式为jpg、jpeg、png、bmp图片或pdf类型的文件
            </div>
          </uploadFile>
        </el-form-item>

        <el-form-item>
          <el-button @click="goBack" size="medium" round plain>返回</el-button>
          <el-button type="primary" @click="submit" size="medium" round
            >确认提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '添加检疫信息',
      productId: '',
      afcBatchId: '',
      companyId: '',
      productParams: { enableFlag: 1 },
      batchParams: { inOutFlag: 2, enableFlag: 1, tacheTypeId: 3 },
      companyParams: {
        enableFlag: 1,
        companyTypeId: 104 // 检疫机构104
      },
      businessType: 'quarantine', // 业务类型（检疫信息文件）
      listType: 'text', // 显示列表类型
      fileList: [],
      // fileType: '/.pdf$|.doc$|.docx$|.PDF$|.DOC$|.DOCX$|.jpg$|.jpeg$|.png$|.bmp$|.JPG$|.JPEG$|.PNG$|.BMP$|/',
      options: [{
        value: 1,
        label: '合格'
      }, {
        value: 2,
        label: '不合格'
      }],
      isOpen: false, // 默认可选择产品批次编号
      // 表单对象
      form: {
        productId: '', // 产品名称ID
        productName: '', // 产品名称
        afcBatchId: '', // 产品批次编号ID
        batchNum: '', // 产品批次编号
        aqCompanyId: '', // 检疫机构ID
        aqCompanyName: '', // 检疫机构
        aqDate: '', // 检疫时间
        aqFlag: 1, // 检疫是否合格 1=合格 2=不合格
        aqRemark: '', // 检疫备注
        files: [] // 文件上传
      },
      rules: {
        productId: [
          { required: true, message: '请选择产品名称', trigger: 'change' }
        ],
        afcBatchId: [
          { required: true, message: '请选择产品批次编号', trigger: 'change' }
        ],
        aqCompanyId: [
          { required: true, message: '请选择检测机构名称', trigger: 'change' }
        ],
        aqFlag: [
          { required: true, message: '请选择是否检疫合格', trigger: 'change' }
        ],
        aqDate: [
          {
            required: true,
            message: '请选择检疫日期',
            trigger: 'change'
          }
        ],
        aqRemark: [
          {
            type: 'string',
            required: true,
            message: '请填写检疫备注',
            trigger: 'blur'
          }
        ],
        files: [
          {
            type: 'array',
            required: true,
            message: '请至少上传1个附件',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  watch: {},
  created() {
    if (this.id) {
      this.title = '修改检疫信息'
      this.isOpen = true // 关闭产品批次选择
      this.get()
    }
  },
  methods: {
    goBack() {
      this.$router.push('/listQuarantines')
    },
    // 选择产品名称
    onProductChange(val) {
      if (!val) {
        this.form.productId = ''
        this.form.productName = ''
      } else {
        this.form.productId = val.productId
        this.form.productName = val.productName
      }
    },

    // 选择产品批次编号
    onBatchChange(val) {
      if (!val) {
        this.form.afcBatchId = ''
        this.form.batchNum = ''
      } else {
        this.form.afcBatchId = val.afcBatchId
        this.form.batchNum = val.batchNum
      }
    },

    // 选择检疫机构
    onCompanyChange(val) {
      if (!val) {
        this.form.aqCompanyId = ''
        this.form.aqCompanyName = ''
      } else {
        this.form.aqCompanyId = val.companyId
        this.form.aqCompanyName = val.companyName
      }
    },
    submit() {
      this.$refs['form'].validate(async(valid) => {
        if (!valid) return
        else {
          if (this.id) {
            this.edit()
          } else {
            this.add()
          }
        }
      })
    },

    // 查询
    async get() {
      const params = {
        afcBatchAqId: this.id
      }
      const { data } = await this.$http.quarantinesInfor.getQuarantine(params)
      if (data.code !== '0') return this.$message.error(data.msg || '查询失败')
      // 回显表单
      console.log('查询数据：', data.data)
      this.form = data.data
      this.companyId = data.data.aqCompanyId
      this.afcBatchId = data.data.afcBatchId
      this.productId = data.data.productId
      for (var item of data.data.files) {
        const file = {
          name: item.originalName,
          url: item.fileUrl
        }
        this.fileList.push(file)
      }
      console.log('fileList:', this.form.files)
    },
    // 添加
    async add() {
      const params = { ...this.form }
      const { data } = await this.$http.quarantinesInfor.saveQuarantine(params)
      if (data.code !== '0') return this.$message.error(data.msg || '新增失败')
      this.$message.success('新增成功')
      this.goBack()
    },
    // 修改
    async edit() {
      const params = { ...this.form }
      const { data } = await this.$http.quarantinesInfor.updateQuarantine(params)
      if (data.code !== '0') return this.$message.error(data.msg || '修改失败')
      this.$message.success('修改成功')
      this.goBack()
    },

    /** 文件上传相关 */
    handleAvatarSuccess(res, file) {
      if (res.code === '0') {
        this.form.files.push(res.data[0])
        this.$refs.form.validateField('files')
        // this.imgUrl = URL.createObjectURL(file.raw)
        // this.fileList = [{ name: res.data[0].originalName, fileUrl: res.data[0].file }]
      } else {
        this.$message.error(res.msg || '添加失败')
      }
    },

    onRemove(file) {
      var files = this.form.files
      for (var i = 0; i < files.length; i++) {
        if (files[i].fileUrl === file.url) {
          files.splice(i, 1)
        }
      }
      this.form.files = files
    }

  }
}
</script>

<style lang="scss">
.save-form-page {
  .el-form {
    margin-top: 40px;
  }
  .warning {
    color: #e6a23c !important;
  }

  .el-input {
    width: 400px;
  }

  .el-textarea {
    width: 400px;
  }

  .upload-demo {
    width: 600px;
  }

  //按钮图标样式调整
  .iconfont {
    font-family: 'iconfont' !important;
    font-size: 14px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
</style>
